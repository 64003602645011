import type { Locale } from "./en"; const fr: Locale = {

  invalidJwtToken: "Désolé, mais votre jeton n'est pas valide",


  home: 'Maison',
  lng: "English",


  error_password: 'Veuillez choisir un mot de passe fort.',
  error_passwordConfirmation: 'Vos mots de passe ne correspondent pas.',
  error_missingUser: "Désolé, nous n'avons pas pu trouver cet utilisateur.",
  error_userInactive: "Votre compte a été désactivé.",
  error_badPassword: 'Désolé! Votre mot de passe est erroné.',

  error_firstName: 'Veuillez entrer un prénom.',
  error_lastName: 'Veuillez entrer un nom de famille.',
  error_email: "S'il vous plaît, mettez une adresse email valide.",
  error_missingProgram: "Veuillez sélectionner un programme auquel accorder l'accès.",
  returnHome: 'Maison',

  signIn: 'Se connecter',
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  confirmPassword: 'Confirmer le mot de passe',
  activateMyAccount: 'Activer mon compte',
  resetMyPassword: 'Réinitialiser mon mot de passe',
  'forgotYourPassword?': 'Mot de passe oublié?',
  requestPasswordReset: 'Réinitialiser mon mot de passe',
  sentAPasswordReset: 'Veuillez vérifier votre boîte de réception pour les instructions pour réinitialiser votre mot de passe.',
  strongPassphrase: 'Mot de passe fort',
  test_minimum: 'Le mot de passe doit contenir au moins 10 caractères.',
  test_maximum: 'Le mot de passe doit contenir moins de 128 caractères.',
  test_sequence: 'Le mot de passe ne doit pas contenir 3 caractères ou plus répétés successivement.',
  test_lowercase: 'Le mot de passe doit contenir au moins une lettre minuscule.',
  test_uppercase: 'Le mot de passe doit contenir au moins une lettre majuscule.',
  test_number: 'Le mot de passe doit contenir au moins un chiffre.',
  test_special: 'Le mot de passe doit contenir au moins un caractère spécial.',

  myPrograms: 'Mes Programmes',
  warranty: 'Garantie',
  rebates: 'Rabais',
  loyalty: 'Loyauté',
  businessDevelopment: 'Développement des affaires',
  napaCanadaPortal: 'Portail NAPA Canada',
  teamMembers: "Membres de l'équipe",
  addTeamMember: "Ajouter le membre de l'équipe",
  programAccess: 'Accès au programme',
  addATeamMember: "Ajouter le membre de l'équipe",
  teamMember: "Membre de l'équipe",
  programSelection: 'Sélection de programme',
  grantAccessTo: "Accorder l'accès à",
  editTeamMemberPermissions: "Modifier les autorisations des membres de l'équipe",
  thankYouForActivating: "Merci d'avoir activé votre compte. Veuillez vérifier votre email pour définir votre mot de passe.",
  invalidEmailDomain: "Désolé, ce domaine n'est pas accepté.",

  programNamePrograms: 'Mes programmes NAPA',
  programNamePrestige: 'NAPA Prestige',
  programNameOnTrack: 'NAPA OnTrack',
  programNameRebates: 'Rabais aux consommateurs NAPA',
  programNameCoop: 'COOP NAPA',
  programName10400Warranty: "Garantie d'entretien NAPA 10/400",
  programNameRoadside: 'Assistance routière NAPA 24/7',
  programNameBackend: 'Espace Membre NAPA',

  dashboard: "Tableau de bord",
  logOut: 'Fermer la session',


  firstName: 'Prénom',
  lastName: 'Nom de famille',
  email: 'Adresse courriel',
};
export default fr;
