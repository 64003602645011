const en = {
  lng: "Français",
  home: "Home",
  logOut: "Log out",
  myNapaPrograms: "My NAPA Programs",

  error_password: 'Please choose a strong password.',
  error_passwordConfirmation: 'Your passwords do not match.',
  error_missingUser: 'Sorry, we couldn\'t find that user.',
  error_userInactive: "This user has been deactivated.",
  error_badPassword: 'Sorry, your password is incorrect.',

  error_firstName: 'Please enter a first name.',
  error_lastName: 'Please enter a last name.',
  error_email: 'Please enter a valid email address.',
  error_missingProgram: 'Please select a program to grant access to.',
  returnHome: 'Return Home',

  activateMyAccount: 'Activate My Account',
  resetMyPassword: 'Reset My Password',

  signIn: 'Sign In',
  username: "Username",
  password: "Password",
  confirmPassword: 'Confirm Password',
  'forgotYourPassword?': 'Forgot your password?',
  'requestPasswordReset': 'Request Password Reset',
  sentAPasswordReset: 'Please check your inbox for instructions on resetting your password.',

  thankYouForActivating: 'Thank you for activating your account. Please check your email to set your password.',
  invalidEmailDomain: 'Sorry, this domain is not accepted.',

  strongPassphrase: 'Strong Passphrase',
  test_minimum: 'The password must be at least 10 characters long.',
  test_maximum: 'The password must be fewer than 128 characters.',
  test_sequence: 'The password may not contain sequences of three or more repeated characters.',
  test_lowercase: 'The password must contain at least one lowercase letter.',
  test_uppercase: 'The password must contain at least one uppercase letter.',
  test_number: 'The password must contain at least one number.',
  test_special: 'The password must contain at least one special character.',

  myPrograms: 'My Programs',
  programAccess: 'Program Access',
  addATeamMember: 'Add a Team Member',
  addTeamMember: 'Add Team Member',
  teamMember: 'Team Member',
  programSelection: 'Program Selection',
  grantAccessTo: 'Grant access to',

  warranty: 'Warranty',
  rebates: 'Rebates',
  loyalty: 'Loyalty',
  businessDevelopment: 'Business Development',
  administration: 'Administration',
  napaCanadaPortal: 'NAPA Canada Portal',
  editTeamMemberPermissions: 'Edit Team Member Permissions',


  programNamePrograms: 'My NAPA Programs',
  programNameCRM: 'NAPA CRM',
  programNamePrestige: 'NAPA Prestige',
  programNameOnTrack: 'NAPA On Track',
  programNameRebates: 'NAPA Consumer Rebates',
  programNameCoop: 'NAPA COOP',
  programName10400Warranty: 'NAPA 10/400 Warranty',
  programNameRoadside: 'NAPA Roadside Assistance',
  programNameBackend: 'NAPA Backend',
  teamMembers: 'Team Members',

  dashboard: "Dashboard",

  invalidJwtToken: "Sorry, but your token is invalid",

  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Cell Phone',
  email: 'Email',
} as const;
type DefaultLanguage = typeof en;
export type Locale = Record<keyof DefaultLanguage, any>;
export default en;
